<template>
  <div class="notfound">
    <div class="box">
      <img src="../../assets/images/noDataImg.png" alt="" />
      <div>暂无权限</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notfound",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.notfound {
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    img {
      width: 210px;
    }
    div {
      font-size: 14px;
      width: 210px;
      text-align: center;
    }
  }
}
</style>
